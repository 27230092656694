<template>
  <!-- 线上订单 -->
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <div class="right">
          <!-- 输入搜索 -->
          <div class="search">
            <el-input style="margin-right:10px;" placeholder="请输入店铺名称" suffix-icon="el-icon-search"
              v-model="shopNameCode" :clearable="true" @change="onCurrentPage"></el-input>
          </div>
          <div class="search">
            <el-input style="margin-right:10px;" placeholder="请输入订单编号" suffix-icon="el-icon-search" v-model="orderCode"
              :clearable="true" @change="onCurrentPage"></el-input>
          </div>
          <!-- 下拉搜索 -->
          <div class="select">
            <!-- 选择状态 -->
            <el-select v-model="payType" placeholder="请选择支付类型" clearable @change="onCurrentPage">
              <el-option v-for="item in paymentType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!--创建时间 -->
            <!-- <el-date-picker v-model="formInline.time" @change="gettime" value-format="yyyy-MM-dd" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="sift-data">
            </el-date-picker> -->
            <el-date-picker v-model="formInline.time" @change="gettime" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期"
              style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none;"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>

        </div>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="orderCode" label="订单编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="shopCode" label="店铺编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="shopName" label="店铺名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="orderAmount" label="销售金额(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="payAmount" label="实收金额(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="payType" label="支付类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ getOrderTypeText(scope.row.payType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" label="创建时间" width="200" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      startTime: '', //开始时间
      endTime: '', //结束时间
      formInline: {
        time: []
      }, //日期区间
      // 支付类型
      paymentType: [{
        value: '0',
        label: '现金支付'
      }, {
        value: '2',
        label: '微信'
      }, {
        value: '1',
        label: '支付宝'
      }, {
        value: '4',
        label: '银联'
      }, {
        value: '5',
        label: '其他'
      },],
      payType: '', //支付类型
      orderCode: '', //订单编号
      shopNameCode: '', //店铺编号
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersList, {
        params: {
          orderSource: 1, //订单来源：1线下；2线上商城(必填)
          orderCode: this.orderCode || null, //订单编号
          payType: this.payType || null, //支付类型
          // shopId: this.$store.state.userInfo.shopId,//登录
          startTime: this.startTime || null, //开始时间
          endTime: this.endTime || null, //结束时间
          shopName: this.shopNameCode || null, //店铺名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const {
          code,
          result
        } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : "";
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : "";
      this.onCurrentPage()
      // console.log('开始时间:', this.startTime, '结束时间:', this.endTime);
    },

    // 标号搜索+清除路径上的参数
    // labelSearch() {
    //   this.getRequest()
    //   if (!this.searchLabelTxt) {
    //     window.history.replaceState(null, null, this.gelDelURL());
    //   }
    // },
    // // 清空路径上携带的参数
    // gelDelURL() {
    //   return window.location.href
    //     .substring(0, window.location.href.indexOf("?"));
    // },

    // tab栏选择
    handleClick(tab) {
      const {
        name
      } = tab
      switch (name) {
        case 'due':
          console.log('待付款');
          return;
        case 'inProcess':
          console.log('处理中');
          return;
        case 'completed':
          console.log('已完成');
          return;
        case 'cancelled':
          console.log('已取消');
          return;
        default:
          console.log('全部订单');
          return;
      }

    },
    // 查看按钮
    handleCommand(row) {
      console.log(row);
      this.$router.push({
        name: 'offlineOrderDet',
        query: {
          orderId: row.orderId
        }
      }) //线下详情
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest() //请求数据
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },

    // 订单子状态文字颜色
    getOrderFormStatusColor(status) {
      switch (status) {
        case '1':
          return 'red'; //待付款
        case '2':
          return 'red'; //待接单
        case '8':
          return 'red'; //待退款
        default:
          return '';
      }
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '1':
          return '支付宝';
        case '2':
          return '微信';
        default:
          return '现金';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;

      .right {
        display: flex;
        margin-bottom: 20px;

        .search {
          margin-right: 10px;
        }

        .header-btn {
          margin-left: 10px;
        }

        .select {
          display: flex;
          justify-content: space-between;

          .el-select {
            margin-right: 10px;
            width: 200px;
          }

        }

      }
    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

  .el-date-editor,
  .el-range-editor,
  .el-input__inner,
  .el-date-editor--daterange {
    margin-left: 0px !important;
  }

  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 250px;

  }

}
</style>
